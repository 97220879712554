const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const data = [
  {
    id: 1,
    src:
      window.innerWidth < 500
        ? "/Videos/AGM_Spook_Mobile.mp4"
        : "/Videos/AGM_Spook.mp4",
    position: {
      lg: [-10, 0, -40],
      md: [-8, 0, -40],
      sm: [-5, 0, -40],
    },

    rotation: [0, 0.5, 0],
    args: [14 * 0.6, 9 * 0.8],
    isLogo: true,
    isHeader: {
      position: {
        lg: [0, 4.5, 0],
        md: [0, 4.5, 0],
        sm: [0, 4.5, 0],
      },
      text: "AGM Spook",
      height: {
        lg: 0.1,
        md: 0.1,
        sm: 0.1,
      },
      size: {
        lg: 1,
        md: 1,
        sm: 1,
      },
    },
    trigger: ".a",
    cameraPosition: {
      lg: { x: -6, y: 0, z: -32 },
      md: { x: -8, y: 0, z: -29 },
      sm: { x: -5, y: 0, z: -29 },
    },
    mdScale: [0.5, 0.5, 0.5],
    smScale: [0.5, 0.5, 0.5],
  },
  {
    id: 2,
    text:
      window.innerWidth < 900 ? "Launching\nSoon" : "Launching Soon",
    size: {
      lg: 1,
      md: 0.8,
      sm: 1,
    },
    position: {
      lg: [70, 2, -60],
      md: [73, 2, -70],
      sm: [73, 2, -70],
    },
    trigger: ".b",
    cameraPosition: {
      lg: { x: 70, y: 0, z: -50 },
      md: { x: 70, y: 0, z: -50 },
      sm: { x: 70, y: 0, z: -50 },
    },
  },

  {
    id: 3,
    src: "/Model/Instagram.obj",
    position: {
      lg: [120, 4, -120],

      md: [115, 4, -120],
      sm: [115, 4, -130],
    },

    rotation: [0, -0.5, 0],
    scale: [0.009, 0.009, 0.009],
    trigger: ".c",
    cameraPosition: {
      lg: { x: 120, y: 0, z: -100 },
      md: { x: 110, y: 0, z: -90 },
      sm: { x: 115, y: 0, z: -90 },
    },
  },

  {
    id: 4,
    position: {
      lg: [19, 1, -160],
      md: [19, 1, -160],
      sm: [30, 1, -160],
    },
    trigger: ".d",
    cameraPosition: {
      lg: { x: 30, y: 0, z: -140 },
      md: { x: 30, y: 0, z: -120 },
      sm: { x: 40, y: 0, z: -100 },
    },
  },
  {
    id: 5,
    text: "Artists, Producers,\nManagers, Writers",

    position: {
      lg: [40, 200, -200],
      md: [40, 200, -200],
      sm: [40, 200, -200],
    },
    size: {
      lg: 1,
      md: 1,
      sm: 0.6,
    },
    hasButton: true,
    btn: {
      position: [40, 197.5, -200],
      btnSource: "/Images/btn.png",
      link: "https://forms.gle/tDn8QUG8KTx5Vqg48",
    },
    trigger: ".e",
    cameraPosition: {
      lg: { x: 40, y: 0, z: -180 },
      md: { x: 40, y: 0, z: -180 },
      sm: { x: 40, y: 0, z: -160 },
    },
    needsAnimation: true,
  },
  {
    id: 6,
    src:
      window.innerWidth < 500
        ? "/Videos/Sell_Music_On_3SET_MB.mp4"
        : "/Videos/Sell_Music_On_3SET.mp4",
    position: {
      lg: [-10, 0, -240],
      md: [-5, 0, -240],
      sm: [2, 0, -220],
    },
    rotation: [0, -0.3, 0],
    args: [14 * 0.6, 9 * 0.8],
    trigger: ".f",
    cameraPosition: {
      lg: { x: 0, y: 0, z: -220 },
      md: { x: 0, y: 0, z: -200 },
      sm: { x: 0, y: 0, z: -190 },
    },
    play: true,
  },
  {
    id: 7,
    src: "/Images/logo.png",
    position: {
      lg: [0, 2, 5],
      md: [0, 2, 5],
      sm: [0, 2, 5],
    },
    scale: {
      lg: [2, 2],
      md: [1.5, 1.5],
      sm: [1.2, 1.2],
    },
  },
  {
    id: 8,
    isBg: true,
    src: "/Videos/bg.mp4",
    position: {
      lg: [-70, 30, -400],
      md: [0, 30, -400],
      sm: [0, 40, -400],
    },
    scale: {
      lg: [16 * 4, 9 * 5, 1],
      md: [16 * 4, 9 * 5, 1],
      sm: [9 * 3, 16 * 3, 1],
    },
    args: [16, 9],
  },
  {
    id: 8,
    src: "/Videos/Bio_Hand_Click.mp4",
    position: {
      lg: [-4, -2, -38],
      md: [-3, -1, -37],
      sm: [-0.4, -2, -37],
    },
    trigger: ".g",

    rotation: [0, 0.5, 0],
    args: [1, 1],
  },
];
export default data;
