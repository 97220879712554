import React from "react";
import { Center, Html, useProgress } from "@react-three/drei";
import "./Loader.css";
import { useEffect } from "react";

export default function Loader({ setIsLoading }) {
  const { progress, item } = useProgress();
  useEffect(() => {
    if (progress > 95) {
      //wait for a second
      setTimeout(() => {
        setIsLoading(false);
      }, [1000]);
    }
  }, [progress]);

  return (
    <Center top>
      <Html>
        <div className='loader-container'>
          <img src='/Images/logo.png' alt='loader' />
          <span className='loader-counter'>
           {progress.toFixed(0)}
          </span>
        </div>
      </Html>
    </Center>
  );
}
