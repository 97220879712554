import React, { useRef,useEffect } from "react";
import { Text3D, Center } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import Teko from "../../Assets/Fonts/Teko_Bold.json";
import { gsap } from "gsap";
import { ShaderMaterial } from "three";
import * as THREE from "three";

import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

export default function Text({ text }) {
  const textRef = useRef();
  const centerRef = useRef();

  const { camera } = useThree();
  const raycaster = new THREE.Raycaster();
 useEffect(() => {
   
    const onDocumentMouseDown = (event) => {
      if (textRef.current) {
        var mouse = new THREE.Vector2();
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, camera);
        var intersects = raycaster.intersectObjects([textRef.current]);

        if (intersects.length > 0) {
          //navigate to a link
          window.open(
            "https://www.instagram.com/3set.io/",
            "_blank"
          );
        }
      }
    };

    document.addEventListener("mousedown", onDocumentMouseDown);
    document.addEventListener("touchstart", onDocumentMouseDown);

  }, []);
    



  useFrame((state) => {
    if (!text.needsAnimation) {
      const t = state.clock.getElapsedTime();
      textRef.current.rotation.set(
        Math.cos(t / 4) / 3,
        Math.sin(t / 4) / 16,
        0
      );
      textRef.current.position.y = (1 + Math.sin(t / 1.5)) / 10;
    }
    if (text.needsAnimation) {
      if (
        camera.position.z - centerRef.current.position.z <
        (window.innerWidth < 500 ? 75 : 50)
      ) {
        gsap.to(centerRef.current.position, {
          y: 0,
          ease: "power4.out",
        });
      } else {
        gsap.to(centerRef.current.position, {
          y: 200,
          ease: "power4.out",
        });
      }
    }
  });
  const DisplacementShader = {
    uniforms: {
      color: { value: new THREE.Color(0xffffff) },
      light: { value: new THREE.Vector3(1, 1, 1) },
      shadow: { value: new THREE.Vector3(0, 0, 0) },
      shadowColor: { value: new THREE.Color(0x000000) },
    },
    vertexShader: `
    varying vec3 vNormal;
    varying vec3 vPosition;

    void main() {
      vNormal = normal;
      vPosition = position;

      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
    fragmentShader: `
    uniform vec3 color;
    uniform vec3 light;
    uniform vec3 shadow;
    uniform vec3 shadowColor;
  
    varying vec3 vNormal;
    varying vec3 vPosition;
  
    void main() {
      vec3 normal = normalize(vNormal);
      vec3 lightDirection = normalize(light);
      vec3 shadowDirection = normalize(shadow);
  
      float intensity = max(dot(normal, lightDirection), 0.0);
      float shadowIntensity = max(dot(normal, shadowDirection), 0.0);
  
      gl_FragColor = vec4(
        mix(shadowColor, color, shadowIntensity) * intensity,
        1.0
      );
    }
  `,
  };

  const shader = new ShaderMaterial({
    uniforms: DisplacementShader.uniforms,
    vertexShader: DisplacementShader.vertexShader,
    fragmentShader: DisplacementShader.fragmentShader,
    side: THREE.BackSide,
  });
  return (
    <Center
      ref={centerRef}
      position={
        window.innerWidth < 500
          ? text.position.sm
          : window.innerWidth < 1000
          ? text.position.md
          : text.position.lg
      }
    >
      <Text3D
        ref={textRef}
        font={Teko}
        smooth={0.001}
        letterSpacing={0.04}
        height={
          text.height
            ? window.innerWidth < 500
              ? text.height.sm
              : window.innerWidth < 1000
              ? text.height.md
              : text.height.lg
            : 0.1
        }
        lineHeight={0.8}
        size={
          window.innerWidth < 500
            ? text.size.sm
            : window.innerWidth < 1000
            ? text.size.md
            : text.size.lg
        }
        castShadow
        receiveShadow
        lightColor='#ffffff'
      >
        {text.text}
      </Text3D>
    </Center>
  );
}
