import React, { Suspense, useState, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { MeshReflectorMaterial } from "@react-three/drei";
import * as THREE from "three";
import data from "./data.js";
import "./index.css";
import Video from "./Video";
import Logo from "./Logo";
import Instagram from "./Instagram";
import Sphere from "./Sphere.jsx";
import Text from "./Text.jsx";
import Loader from "../Loader/Loader.jsx";
import SignUpBtn from "./SignUpBtn.jsx";
import CameraController from "./CameraController.jsx";
import Instruction from "../ScrollInstruction/Instruction.jsx";
import IconCube from "./IconCube.jsx";
import TapInstruction from "../ScrollInstruction/TapInstruction.jsx";
import {OrbitControls} from "@react-three/drei";

const Lighting = () => {
  return (
    <>
      <ambientLight intensity={0.1} />
      <directionalLight position={[-1, 0, -1]} intensity={1} castShadow />
      <pointLight position={[-10, 0, -20]} intensity={0.5} castShadow />
      <pointLight position={[0, -10, 0]} intensity={0.1} castShadow />
      {/* <OrbitControls /> */}
    </>
  );
};

const CameraLookat = () => {
  const { camera } = useThree();
  useFrame(({ pointer }) => {
    //camera lookat pointer using lerp

    camera.rotation.y = THREE.MathUtils.lerp(
      camera.rotation.y,
      -pointer.x * 0.1,
      0.02
    );
    camera.rotation.x = THREE.MathUtils.lerp(
      camera.rotation.x,
      pointer.y * 0.1,
      0.01
    );
  });
  return null;
};

export default function Index() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {" "}
      <div className='canvas-container'>
        <div className='main-div'></div>
        <Canvas
          camera={{
            fov: 45,
            near: 0.1,
            far: 1000,
            position: [0, 2, 8],
          }}
          shadows
        >
          <Lighting />

          {!isLoading && (
            <>
              <Logo logo={data[6]} />
              <SignUpBtn btn={data[4].btn} />
            </>
          )}
          <CameraLookat />

          <Suspense fallback={<Loader setIsLoading={setIsLoading} />}>
            <mesh position={[0, -4, 20]} rotation={[-Math.PI / 2, 0, 0]}>
              <planeGeometry args={[500, 900]} />
              <MeshReflectorMaterial
                blur={[800, 100]}
                resolution={380}
                mixBlur={1}
                mixStrength={100}
                depthScale={2}
                minDepthThreshold={0.85}
                color='#151515'
                metalness={0.6}
                roughness={1}
              />
            </mesh>
            <Text text={data[4]} />
            <Sphere sphere={data[3]} />
            <Text text={data[1]} 
            />
            <Instagram model={data[2]} />
            <Video videoData={data[0]} />
            <Video videoData={data[5]} />
            <Video videoData={data[7]} />
            <IconCube videoData={data[8]} />
            <CameraController />
          </Suspense>
        </Canvas>
      </div>
      {!isLoading && (
        <>
          <Instruction />
          <TapInstruction />
        </>
      )}
    </>
  );
}
