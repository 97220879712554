import React, { useMemo, useRef, useEffect } from "react";
import * as THREE from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { gsap } from "gsap";

export default function SignUpBtn({ btn }) {
  const { camera } = useThree();
  const raycaster = new THREE.Raycaster();
  const btnRef = useRef();
  const texture = useMemo(() => {
    return new THREE.TextureLoader().load(btn.btnSource);
  }, []);

  useEffect(() => {
    const onDocumentMouseDown = (event) => {
      if (btnRef.current) {
        var mouse = new THREE.Vector2();
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, camera);
        var intersects = raycaster.intersectObjects([btnRef.current]);

        if (intersects.length > 0) {
          let name = intersects[0].object.name;
          //open new tab
          window.open(btn.link, "_blank");
        }
      }
    };
    document.addEventListener("mousedown", onDocumentMouseDown);
    document.addEventListener("touchstart", onDocumentMouseDown);
  }, []);

  useFrame(() => {
    if (
      camera.position.z - btnRef.current.position.z <
      (window.innerWidth < 770 ? 80 : 50)
    ) {
      gsap.to(btnRef.current.position, {
        y: -2.5,
        ease: "power4.out",
      });
    } else {
      gsap.to(btnRef.current.position, {
        y: 197.5,
        ease: "power4.out",
      });
    }
  });

  return (
    <group>
      <mesh
        ref={btnRef}
        position={btn.position}
        onPointerEnter={() => {
          document.body.style.cursor = "pointer";
        }}
        onClick={() => {
          window.open(btn.link, "_blank", "noreferrer");
          //
        }}
        receiveShadow
        castShadow
      >
        <planeGeometry args={[5, 1.5, 1]} />
        <meshBasicMaterial map={texture} />
      </mesh>
    </group>
  );
}
