import { useFrame, useThree } from "@react-three/fiber";
import React, { useState, useEffect, useRef } from "react";
import * as THREE from "three";

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const IconCube = ({ videoData }) => {
  const raycaster = new THREE.Raycaster();
  const { camera } = useThree();
  const [video] = useState(() => {
    const video = document.createElement("video");
    video.src = videoData.src;
    video.loop = true;
    video.preload = true;

    if (!isIOS) {
      video.muted = true;
    }
    if (isIOS) {
      video.muted = true;
      video.setAttribute("playsinline", true);
      video.setAttribute("x5-video-player-type", "h5");
    }

    return video;
  });

  const ref = React.useRef();

  useEffect(() => {
    video.play();
    const onDocumentMouseDown = (event) => {
      if (ref.current) {
        var mouse = new THREE.Vector2();
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, camera);
        var intersects = raycaster.intersectObjects([ref.current]);

        if (intersects.length > 0) {
          //navigate to a link
          window.open(
            "https://embed.vidello.com/5592/yfydimtk0zbo2yi5/index.html",
            "_blank"
          );
        }
      }
    };

    document.addEventListener("mousedown", onDocumentMouseDown);
    document.addEventListener("touchstart", onDocumentMouseDown);
  }, []);

  return (
    <group
      ref={ref}
      rotation={videoData.rotation}
      position={
        window.innerWidth < 770
          ? videoData.position.sm
          : window.innerWidth < 1000
          ? videoData.position.md
          : videoData.position.lg
      }
    >
      <mesh
        scale={
          videoData.isBg
            ? window.innerWidth < 770
              ? videoData.scale.sm
              : window.innerWidth < 1000
              ? videoData.scale.md
              : videoData.scale.lg
            : [1, 1, 1]
        }
      >
        <boxGeometry args={videoData.args} />
        <meshStandardMaterial
          encoding={THREE.sRGBEncoding}
          side={THREE.FrontSide}
        >
          <videoTexture
            attach='map'
            args={[video]}
            encoding={THREE.sRGBEncoding}
            unSignedByte={true}
          />
        </meshStandardMaterial>
      </mesh>
    </group>
  );
};

export default IconCube;
