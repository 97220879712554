import React, { useRef, useState } from "react";
import { PositionalAudio } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect } from "react";
import * as THREE from "three";
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export default function Sound({ src, parentRef, checkDistance }) {
  const ref = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [playedOnce, setPlayedOnce] = useState(false);
  const raycaster = new THREE.Raycaster();
  const { camera } = useThree();
  useEffect(() => {
    const onDocumentMouseDown = (event) => {
      if (ref.current) {
        var mouse = new THREE.Vector2();
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, camera);

        if (isIOS && !playedOnce) {
          ref.current.play();
          setPlayedOnce(true);
        }
      }
    };
    if (isIOS) {
      document.addEventListener("mousedown", onDocumentMouseDown);
      document.addEventListener("touchstart", onDocumentMouseDown);
    }
  }, []);

  useFrame(({ camera, clock }) => {
    const distance = camera.position.distanceTo(parentRef.current.position);
    if (distance < checkDistance && !isPlaying) {
      setIsPlaying(true);
      ref.current.play();
    } else if (distance >= checkDistance && isPlaying) {
      setIsPlaying(false);
      ref.current.pause();
    }
  });

  return (
    <PositionalAudio
      ref={ref}
      url={src}
      distance={isIOS ? 0.1 : 3}
      loop={false}
    />
  );
}
