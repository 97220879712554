import logo from "./logo.svg";
import "./App.css";
import Landing from "./Pages/Landing";

function App() {
  return (
    <div className='App'>
      <Landing />
    </div>
  );
}


export default App;
