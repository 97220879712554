import React, { useEffect } from "react";
import { useThree } from "@react-three/fiber";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import data from "./data.js";
gsap.registerPlugin(ScrollTrigger);

export default function CameraController() {
  let { camera } = useThree();
  const width = window.innerWidth;

  useEffect(() => {
    const t1 = gsap.timeline();
    t1.to(camera.position, {
      z: -10,
      onUpdate: () => {
        gsap.to(camera.rotation, {
          y: 0,
          duration: 3,
          ease: "power2.easeout",
        });
      },
    })
      .to(camera.position, {
        z:
          width < 770
            ? data[0].cameraPosition.sm.z
            : width < 1000
            ? data[0].cameraPosition.md.z
            : data[0].cameraPosition.lg.z,
        x:
          width < 770
            ? data[0].cameraPosition.sm.x
            : width < 1000
            ? data[0].cameraPosition.md.x
            : data[0].cameraPosition.lg.x,
        ease: "power2.easeout",
        duration: 3,
      })
      .to(camera.position, {
        z:
          width < 770
            ? data[1].cameraPosition.sm.z
            : width < 1000
            ? data[1].cameraPosition.md.z
            : data[1].cameraPosition.lg.z,
        x:
          width < 770
            ? data[1].cameraPosition.sm.x
            : width < 1000
            ? data[1].cameraPosition.md.x
            : data[1].cameraPosition.lg.x,
        ease: "power2.easeout",
        duration: 3,
        onUpdate: () => {
          gsap.to(camera.rotation, {
            y: camera.rotation.y - 0.3,
            duration: 3,
            ease: "power2.easeout",
          });
        },
      })
      .to(camera.position, {
        z:
          width < 770
            ? data[2].cameraPosition.sm.z
            : width < 1000
            ? data[2].cameraPosition.md.z
            : data[2].cameraPosition.lg.z,
        x:
          width < 770
            ? data[2].cameraPosition.sm.x
            : width < 1000
            ? data[2].cameraPosition.md.x
            : data[2].cameraPosition.lg.x,
        duration: 3,
        ease: "power2.easeout",
        onUpdate: () => {
          gsap.to(camera.rotation, {
            y: camera.rotation.y - 0.1,
            duration: 3,
            ease: "power2.easeout",
          });
        },
      })
      .to(camera.position, {
        z:
          width < 770
            ? data[3].cameraPosition.sm.z
            : width < 1000
            ? data[3].cameraPosition.md.z
            : data[3].cameraPosition.lg.z,
        x:
          width < 770
            ? data[3].cameraPosition.sm.x
            : width < 1000
            ? data[3].cameraPosition.md.x
            : data[3].cameraPosition.lg.x,
        duration: 3,
        ease: "power2.easeout",
        onUpdate: () => {
          gsap.to(camera.rotation, {
            y: camera.rotation.y + 0.4,
            duration: 3,
            ease: "power2.easeout",
          });
        },
      })
      .to(camera.position, {
        z:
          width < 770
            ? data[4].cameraPosition.sm.z
            : width < 1000
            ? data[4].cameraPosition.md.z
            : data[4].cameraPosition.lg.z,
        x:
          width < 770
            ? data[4].cameraPosition.sm.x
            : width < 1000
            ? data[4].cameraPosition.md.x
            : data[4].cameraPosition.lg.x,
        duration: 3,
        ease: "power2.easeout",
      })
      .to(camera.position, {
        z:
          width < 770
            ? data[5].cameraPosition.sm.z
            : width < 1000
            ? data[5].cameraPosition.md.z
            : data[5].cameraPosition.lg.z,
        x:
          width < 770
            ? data[5].cameraPosition.sm.x
            : width < 1000
            ? data[5].cameraPosition.md.x
            : data[5].cameraPosition.lg.x,
        duration: 3,
        ease: "power2.easeout",
        onUpdate: () => {
          gsap.to(camera.rotation, {
            y: camera.rotation.y + 0.3,
            duration: 3,
            ease: "power2.easeout",
          });
        },
      });
    ScrollTrigger.create({
      animation: t1,
      trigger: data[0].trigger,
      start: "top top",
      end: window.innerWidth < 770 ? "+15000" : "+30000",
      scrub: 1.4,
      pin: true,
      anticipatePin: true,
    });
  }, []);
  return null;
}
