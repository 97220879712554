// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lora&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app {\r\n    border: 1px solid red;\r\n    position: relative;\r\n\r\n}\r\n\r\n.logo-img {\r\n    position: fixed;\r\n    top: 1%;\r\n    left: 0;\r\n    width: 10%;\r\n    object-fit: contain;\r\n    z-index: 5;\r\n}\r\n\r\n@media screen and (max-width: 1008px) {\r\n    .logo-img {\r\n        width: 20%;\r\n    }\r\n\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n    .logo-img {\r\n        width: 25%;\r\n    }\r\n\r\n}\r\n\r\n@media screen and (max-width: 468px) {\r\n    .logo-img {\r\n        width: 30%;\r\n    }\r\n\r\n}", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;IACI,qBAAqB;IACrB,kBAAkB;;AAEtB;;AAEA;IACI,eAAe;IACf,OAAO;IACP,OAAO;IACP,UAAU;IACV,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI;QACI,UAAU;IACd;;AAEJ;;AAEA;IACI;QACI,UAAU;IACd;;AAEJ;;AAEA;IACI;QACI,UAAU;IACd;;AAEJ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');\r\n\r\n.app {\r\n    border: 1px solid red;\r\n    position: relative;\r\n\r\n}\r\n\r\n.logo-img {\r\n    position: fixed;\r\n    top: 1%;\r\n    left: 0;\r\n    width: 10%;\r\n    object-fit: contain;\r\n    z-index: 5;\r\n}\r\n\r\n@media screen and (max-width: 1008px) {\r\n    .logo-img {\r\n        width: 20%;\r\n    }\r\n\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n    .logo-img {\r\n        width: 25%;\r\n    }\r\n\r\n}\r\n\r\n@media screen and (max-width: 468px) {\r\n    .logo-img {\r\n        width: 30%;\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
