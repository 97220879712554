import React, { useEffect } from "react";
import Index from "../Components/R3F/Index";
import { Scrollbar } from "smooth-scrollbar-react";
import Instruction from "../Components/ScrollInstruction/Instruction";

export default function Landing() {
  return (
    <div data-scroll-container style={{ position: "relative" }}>
      <img className='logo-img' src='/Images/Logo/logo.png' alt='logo' />

      <Index />
      <Scrollbar>
        <section data-scroll-section style={{ height: "1vh" }}></section>
        <section
          className='a'
          data-scroll-section
          style={{ height: "10vh" }}
        ></section>
      </Scrollbar>

      {/* {data.map((item, index) => (
        <section
          key={index}
          style={{ height: "200vh" }}
          className={item.trigger.substring(1)}
          data-scroll-section
        ></section>
      ))} */}
    </div>
  );
}
