import React, { useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { useEffect } from "react";
import { PositionalAudio } from "@react-three/drei";
import Sound from "./Sound";

const Logo = ({ logo }) => {
  const logoRef = useRef();

  const logoSprite = new THREE.TextureLoader().load(logo.src);
  const spriteMaterial = new THREE.SpriteMaterial({
    map: logoSprite,
    color: 0xffffff,
  });

  useFrame((delta) => {
    if (logoRef.current) spriteMaterial.rotation -= 0.005;
  });

  return (
    <group>
      <mesh
        position={
          window.innerWidth < 770
            ? logo.position.sm
            : window.innerWidth < 1000
            ? logo.position.md
            : logo.position.lg
        }
        ref={logoRef}
      >
        <Sound
          src='/Audio/logoAudio.mp3'
          checkDistance={2}
          parentRef={logoRef}
        />
        <primitive
          object={new THREE.Sprite(spriteMaterial)}
          scale={
            window.innerWidth < 770
              ? logo.scale.sm
              : window.innerWidth < 1000
              ? logo.scale.md
              : logo.scale.lg
          }
        />
      </mesh>
    </group>
  );
};

export default Logo;
