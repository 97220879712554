import React, { useRef } from "react";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { useLoader, useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { useEffect } from "react";

const Instagram = ({ model }) => {
  const obj = useLoader(OBJLoader, model.src);
  const ref = useRef();
  const { camera } = useThree();
  var raycaster = new THREE.Raycaster();

  const texture = new THREE.TextureLoader().load("/Images/gradient.jpg");
  obj.traverse((child) => {
    if (child.isMesh) {
      child.material.map = texture;
      child.material.side = THREE.DoubleSide;
    }
  });

  useEffect(() => {
    if (ref.current) ref.current.name = "instagram";
  }, [ref]);

  useEffect(() => {
    const onDocumentMouseDown = (event) => {
      if (ref.current) {
        var mouse = new THREE.Vector2();
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, camera);
        var intersects = raycaster.intersectObjects(ref.current.children, true);

        if (intersects.length > 0) {
          //open new tab
          window.open("https://linktr.ee/3set.io", "_blank");
        }
      }
    };
    document.addEventListener("mousedown", onDocumentMouseDown);
    document.addEventListener("touchstart", onDocumentMouseDown);
  }, []);

  useFrame((state) => {
    obj.rotation.y += 0.005;
  });

  return (
    <group
      position={
        window.innerWidth < 770
          ? model.position.sm
          : window.innerWidth < 1000
          ? model.position.md
          : model.position.lg
      }
      ref={ref}
    >
      <mesh receiveShadow castShadow>
        <primitive object={obj} rotation={model.rotation} scale={model.scale} />
      </mesh>
    </group>
  );
};

export default Instagram;
