import React from "react";

export default function TapInstruction() {
  const [show, setShow] = React.useState(window.innerWidth < 770);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 8000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='instruction-container'>
      {show ? <h3 className='taptext'>Tap on the Video to Play Sound</h3> : null}
    </div>
  );
}
