import React from "react";
import { SpotLight as Spot } from "@react-three/drei";

export default function SpotLight({ target, position }) {
  return (
    <Spot
      castShadow
      target={target}
      position={position}
      penumbra={0.2}
      radiusTop={0.4}
      radiusBottom={40}
      distance={80}
      angle={0.3}
      attenuation={20}
      anglePower={5}
      intensity={2}
      color='#fcf6c8'
      opacity={0.2}
    />
  );
}
