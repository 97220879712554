import React, { useRef, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import { Cylinder, PositionalAudio } from "@react-three/drei";
import Sound from "./Sound";
import * as THREE from "three";
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

function ccccc(children, color) {
  const fontSize = 550;

  const canvas = document.createElement("canvas");
  canvas.width = isIOS ? 8100 : 15000;
  canvas.height = 2048;
  const context = canvas.getContext("2d");

  context.fillStyle = "transparent";
  context.fillRect(0, 0, canvas.width, canvas.height);

  context.font = `bold ${fontSize}px  -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif`;
  context.textAlign = "center";
  context.textBaseline = "middle";

  context.fillStyle = color;
  context.fillText(children, isIOS ? 3900 : 7000, canvas.height / 2);
  return canvas;
}

function TextRing({ children }) {
  const canvas = useMemo(() => {
    return ccccc(children, "#182648");
  }, [children]);

  const texture = useRef();

  const cylArgs = [1, 1, 1, 64, 1, true];

  return (
    <group position={[0, 0, 0]} rotation-y={Math.PI / 2} scale={[5, 5, 5]}>
      <Cylinder args={cylArgs} side={THREE.FrontSide}>
        <meshStandardMaterial transparent attach='material'>
          <canvasTexture
            attach='map'
            repeat={[1, 1]}
            image={canvas}
            premultiplyAlpha
            ref={texture}
            wrapS={THREE.RepeatWrapping}
            wrapT={THREE.RepeatWrapping}
            onUpdate={(s) => (s.needsUpdate = true)}
          />
        </meshStandardMaterial>
      </Cylinder>
    </group>
  );
}

export default function Sphere({ sphere }) {
  const ref = useRef();
  useFrame(() => {
    ref.current.rotation.y -= 0.01;
  });

  return (
    <mesh
      ref={ref}
      position={
        window.innerWidth < 770
          ? sphere.position.sm
          : window.innerWidth < 1000
          ? sphere.position.md
          : sphere.position.lg
      }
    >
      <Sound
        src='/Audio/repAudio.mp3'
        checkDistance={60}
        parentRef={ref}
        loop={false}
      />
      <TextRing>
        {isIOS
          ? "Rep Yo Side-Rep Yo Set-It's 3SET"
          : " Rep Yo Side - Rep Yo Set - It's 3SET "}
      </TextRing>
      <sphereGeometry args={[4.2, 38, 38]} />
      <meshPhongMaterial color={"#ffd400"} shininess={1000} reflectivity={8} />
    </mesh>
  );
}
