import { useFrame, useThree } from "@react-three/fiber";
import React, { useState, useEffect, useRef } from "react";
import * as THREE from "three";
import SpotLight from "./SpotLight";
import Text from "./Text";
import { gsap } from "gsap";
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const Video = ({ videoData }) => {
  const raycaster = new THREE.Raycaster();
  const { camera } = useThree();
  const [video] = useState(() => {
    const video = document.createElement("video");
    video.src = videoData.src;
    video.loop = true;
    video.preload = true;
    video.setAttribute("playsinline", true);

    if (!isIOS) {
      video.muted = true;
    }
    if (isIOS) {
      video.muted = true;
      video.loop = true;

      video.setAttribute("webkit-playsinline", true);
      video.setAttribute("playsinline", true);
      // video.setAttribute("x5-video-player-type", "h5");

      // video.setAttribute("x5-video-orientation", "portrait");
    }

    return video;
  });

  const ref = React.useRef();

  useFrame(() => {
    if (camera.position.z - ref.current.position.z < 30) {
      gsap.to(camera.rotation, {
        y:
          window.innerWidth < 770 ? 0.1 : window.innerWidth < 1000 ? 0.25 : 0.3,
        duration: 5,
        ease: "power2.easeout",
      });
    }

    if (camera.position.z - ref.current.position.z < 0) {
      gsap.to(camera.rotation, {
        y: 0,
        duration: 5,
        ease: "power2.easeout",
      });
    }

    if (!isIOS) {
      if (videoData.isBg && !video.isPlaying) {
        video.play();
      } else if (
        camera.position.z - ref.current.position.z < 90 &&!video.isPlaying
      ) {
        video.play();
      } else if (video.isPlaying) {
        video.pause();
      }

      if (camera.position.z - ref.current.position.z <(window.innerWidth < 770 ? 50 : 35)
       &&
        camera.position.z - ref.current.position.z > 0) 
        {
        video.muted = false;
      } else {
        video.muted = true;
      }
    }

  });
  useEffect(() => {
    video.play();
    const onDocumentMouseDown = (event) => {
      if (ref.current) {
        var mouse = new THREE.Vector2();
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, camera);
        var intersects = raycaster.intersectObjects([ref.current]);
        if (isIOS && intersects.length > 0) {
          // if(video.isPlaying)
          if (videoData?.isHeader ||
            videoData?.play
            ) video.muted = !video.muted;
        }
      }
    };

    if (isIOS) {
      document.addEventListener("mousedown", onDocumentMouseDown);
      document.addEventListener("touchstart", onDocumentMouseDown);
    }
  }, []);

  return (
    <group
      ref={ref}
      rotation={videoData.rotation}
      position={
        window.innerWidth < 770
          ? videoData.position.sm
          : window.innerWidth < 1000
          ? videoData.position.md
          : videoData.position.lg
      }
    >
      {videoData.isHeader && (
        <>
          <SpotLight target={ref.current} position={[3, 8, 5]} />
          <Text text={videoData.isHeader} />
          <SpotLight target={ref.current} position={[-7, 8, 5]} />
        </>
      )}

      <mesh
        scale={
          videoData.isBg
            ? window.innerWidth < 770
              ? videoData.scale.sm
              : window.innerWidth < 1000
              ? videoData.scale.md
              : videoData.scale.lg
            : [1, 1, 1]
        }
        castShadow
      >
        <boxGeometry args={videoData.args} />
        <meshStandardMaterial
          encoding={THREE.sRGBEncoding}
          side={THREE.FrontSide}
        >
          <videoTexture
            attach='map'
            args={[video]}
            encoding={THREE.sRGBEncoding}
            unSignedByte={true}
          />
        </meshStandardMaterial>
      </mesh>
    </group>
  );
};

export default Video;
