import React from "react";
import "./Instruction.css";
import { useEffect } from "react";
export default function Instruction() {
  useEffect(() => {
    //on scrollup make the page scroll up 
    document.addEventListener("wheel", (e) => {
      if (e.deltaY < 0) {
        window.scrollBy(0, -100);
      }
    });
  }, []);
  


  return (
    <div className='instruction-container'>
      <div className='scroll-downs'>
        <div className='mousey'>
          <div className='scroller'>
            
          </div>
        </div>
      
          <h4>
              Scroll Up
            </h4>
      </div>
    </div>
  );
}
